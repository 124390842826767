<template>
<div class="lesson" ref="cert" :class="{noHeight:type}">
  <el-dialog :title="lessonNm.name" :visible.sync="dialogTableVisible">
      <div style="height: 480px; overflow: auto">
        <img
          :src="certUrl"
          alt=""
        />
      </div>
    </el-dialog>
  <canvas :id="canvasIndex" :width="width" :height="height"></canvas>
  <img :src="certUrl" :preview-src-list="[...certUrl]" @click="toViewImg" />
  <!-- @contextmenu="$event => $event.preventDefault()" -->

</div>
</template>

<script>
import certImg from '@/assets/images/certTJ.jpg'
export default {
  name: "lesson",
  props:['lessonNm','type','index', 'certInfoItem', 'userInfoItem','userName'],
  data() {
    return {
      dialogTableVisible: false,
      certInfo: {},
      userInfo:{
        gender: 2,
        cid: '455652199602152256',
        coverImg: 'https://upload.jianshu.io/users/upload_avatars/6631354/f61123b8-e6b6-4c8f-a207-ad78c8614286?imageMogr2/auto-orient/strip|imageView2/1/w/100/h/100/format/webp',
        birthday: '1990-03-25',
        id: '5658965454',
        certTime: '2022-11-25'
      },
      coverImg:'https://cdn.wormhoo.com/images/2022-12-23/2022122314281833006.jpg',
      width:1600,
      height:1131,
      img: certImg,
      certImg,
      certUrl:'',
      canvas:null,
      ctx:null,
      bg1:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/%E5%88%B0%E5%AE%B6%E9%AB%98%E7%BA%A7%E5%A9%B4%E5%B9%BC%E5%B8%88-%E6%A0%B7%E6%9C%AC.jpg',
    };
  },
  watch:{
    index:{
      handler(){
        if(this.index || this.index === 0){
          this.certInfo = this.certInfoItem
          this.userInfo = this.userInfoItem
          this.$nextTick(()=>{
            this.getData()
          })
          
        }
      },
      immediate: true
    }
  },
  async mounted() {
    // this.getData();
    
  },
  destroyed() {
    window.removeEventListener("scroll", this.onLoad);
  },
  computed: {
    canvasIndex(){
       return this.index || this.index === 0 ? 'canvas'+this.index : 'canvas'
    },
    plat() {
      return this.$store.state.plat_id;
    },
  },
  methods: {
    toViewImg() {
      if(this.index || this.index === 0){
        this.dialogTableVisible = true;
      }
    },
    async getData(id) {
      if(id){
        let res = await this.$request.certInfo(id)
        this.certInfo = res.data.cret_list
        this.userInfo = res.data.user_list
      }
      this.canvas=document.getElementById(this.canvasIndex);
      this.ctx=this.canvas.getContext("2d");
      this.setCavans1()
      // if(this.$route.query.id == '548'){
      //   this.setCavans1()
      // }else{
      //   this.setCavans2()
      // }
      
    },
    setCavans1() {
      const image = new Image();
      // image.src = certImg
      image.src = this.certImg
      image.setAttribute("crossOrigin",'Anonymous')
      image.onload = ()=> {
        this.ctx.drawImage(image, 0, 0, this.width, this.height);
          // 左边姓名
          this.ctx.font = "bold 22px 宋体";
          this.ctx.fillStyle = '#000';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(this.userName || this.userInfoItem.name || '姓名名', 1060, 496);

          // 证书编号
          this.ctx.font = "24px Microsoft YaHei";
          this.ctx.fillStyle = '#000';
          this.ctx.textAlign = 'left'
          this.ctx.fillText(this.certInfo.cert_no || '000000000', 355, 973);

          this.certUrl = this.canvas.toDataURL("image/png");
      }
    },
    jumpDetail(item) {
      this.$router.push(`./detail?id=${item.id}`);
    },
    
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.lesson {
  width: 100%;
  min-height: calc(100vh - 230px);
  text-align: center;
  overflow: hidden;
  canvas{
    opacity: 0;
    position: absolute;
    left: -9999px;
  }
  img{
    width: auto;
    max-width: 100%;
  }
}
.noHeight{
  height: auto;
  min-height: 0;
  img{
    width: auto;
    max-width: 100%;
    cursor: pointer;
  }
}
</style>

<style lang="scss">
</style>
