<template>
<div class="lesson" ref="cert" :class="{noHeight:type}">
  <el-dialog :title="lessonNm" :visible.sync="dialogTableVisible">
      <div style="height: 480px; overflow: auto">
        <img
          @contextmenu="$event => $event.preventDefault()"
          :src="certUrl"
          alt=""
        />
      </div>
    </el-dialog>
  <canvas :id="canvasIndex" :width="width" :height="height"></canvas>
  <img :src="certUrl" :preview-src-list="[...certUrl]" @click="toViewImg"  @contextmenu="$event => $event.preventDefault()"/>
</div>
</template>

<script>
  import certImg from '@/assets/images/cert.jpg'
  import certImg1 from '@/assets/images/cert1.jpg'
  import certImg2 from '@/assets/images/cert2.jpg'
export default {
  name: "lesson",
  props:['lessonNm','type','index', 'certInfoItem', 'userInfoItem'],
  data() {
    return {
      dialogTableVisible: false,
      certInfo: {},
      userInfo:{
        gender: 2,
        cid: '455652199602152256',
        coverImg: 'https://upload.jianshu.io/users/upload_avatars/6631354/f61123b8-e6b6-4c8f-a207-ad78c8614286?imageMogr2/auto-orient/strip|imageView2/1/w/100/h/100/format/webp',
        birthday: '1990-03-25',
        id: '5658965454',
        certTime: '2022-11-25'
      },
      coverImg:'https://cdn.wormhoo.com/images/2022-12-23/2022122314281833006.jpg',
      width:1600,
      height:1208,
      img: certImg,
      certImg1,
      certImg2,
      certUrl:'',
      canvas:null,
      ctx:null,
      bg1:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/%E5%88%B0%E5%AE%B6%E9%AB%98%E7%BA%A7%E5%A9%B4%E5%B9%BC%E5%B8%88-%E6%A0%B7%E6%9C%AC.jpg',
    };
  },
  watch:{
    index:{
      handler(){
        if(this.index || this.index === 0){
          this.certInfo = this.certInfoItem
          this.userInfo = this.userInfoItem
          this.$nextTick(()=>{
            this.getData()
          })
          
        }
      },
      immediate: true
    }
  },
  async mounted() {
    // this.getData();
    
  },
  destroyed() {
    window.removeEventListener("scroll", this.onLoad);
  },
  computed: {
    canvasIndex(){
       return this.index || this.index === 0 ? 'canvas'+this.index : 'canvas'
    },
    plat() {
      return this.$store.state.plat_id;
    },
  },
  methods: {
    toViewImg() {
      if(this.index || this.index === 0){
        this.dialogTableVisible = true;
      }
    },
    async getData(id) {
      if(id){
        let res = await this.$request.certInfo(id)
        this.certInfo = res.data.cret_list
        this.userInfo = res.data.user_list
      }
      this.canvas=document.getElementById(this.canvasIndex);
      this.ctx=this.canvas.getContext("2d");
      this.setCavans1()
      // if(this.$route.query.id == '548'){
      //   this.setCavans1()
      // }else{
      //   this.setCavans2()
      // }
      
    },
    setCavans1() {
      const image = new Image();
      image.src = this.certImg1
      image.setAttribute("crossOrigin",'Anonymous')
      image.onload = ()=> {
        this.ctx.drawImage(image, 0, 0,this.canvas.width, this.canvas.height);

        
        // 证件照
        // const image2 = new Image();
        image.src = this.userInfo.cover;
        image.onload = ()=> {
          // this.ctx.drawImage(image2, 0, 0,this.canvas.width, this.canvas.height);
          this.ctx.drawImage(image, 255, 94,290, 420);
          // 课程名称
          this.ctx.font = "36px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(this.lessonNm, 1260, 380);
        
          // 左边姓名
          this.ctx.font = "40px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(this.userInfo.name, 300, 620);

          // 左边性别
          this.ctx.font = "40px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(this.userInfo.gender == 1 ? '男' : '女', 620, 620);

          // 左边出生年月日
          let birthdayArr = this.userInfo.day_time.split('-')
          this.ctx.font = "40px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(birthdayArr[0], 333, 750);

          this.ctx.font = "40px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(birthdayArr[1], 515, 750);

          this.ctx.font = "40px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(birthdayArr[2], 655, 750);

          // 证书编号
          this.ctx.font = "40px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(this.certInfo.cert_no, 480, 880);

          // 身份证号
          this.ctx.font = "40px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(this.userInfo.cid, 480, 1000);

          // 右边名字
          this.ctx.font = "40px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(this.userInfo.name, 1000, 210);

          let certTimeArr = this.certInfo.day_time.split('-')
          // 右边年月
          this.ctx.font = "40px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(certTimeArr[0], 1215, 210);

          this.ctx.font = "40px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(certTimeArr[1], 1350, 210);

          this.ctx.font = "40px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(certTimeArr[0], 1043, 1045);

          this.ctx.font = "40px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(certTimeArr[1], 1198, 1045);

          this.ctx.font = "40px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(certTimeArr[2], 1315, 1045);
          this.certUrl = this.canvas.toDataURL("image/png");
        }
      }
    },
    setCavans2() {
      const image = new Image();
      image.src = this.certImg2;
      image.setAttribute("crossOrigin",'Anonymous')
      image.onload = ()=> {
        this.ctx.drawImage(image, 0, 0,this.canvas.width, this.canvas.height);
        
        // 证件照
        // const image2 = new Image();
        image.src = this.userInfo.cover;
        image.onload = ()=> {
        // this.ctx.drawImage(image2, 0, 0,this.canvas.width, this.canvas.height);
        this.ctx.drawImage(image, 255, 94,290, 420);

          // 课程名称
        this.ctx.font = "50px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'center'
        this.ctx.fillText(this.lessonNm, 1195, 380);
        
        // 左边姓名
        this.ctx.font = "40px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'center'
        this.ctx.fillText(this.userInfo.name, 300, 620);

        // 左边性别
        this.ctx.font = "40px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'center'
        this.ctx.fillText(this.userInfo.gender == 1 ? '男' : '女', 620, 620);

        // 左边出生年月日
        let birthdayArr = this.userInfo.day_time.split('-')
        this.ctx.font = "40px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'center'
        this.ctx.fillText(birthdayArr[0], 333, 750);

        this.ctx.font = "40px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'center'
        this.ctx.fillText(birthdayArr[1], 515, 750);

        this.ctx.font = "40px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'center'
        this.ctx.fillText(birthdayArr[2], 655, 750);

        // 证书编号
        this.ctx.font = "40px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'center'
        this.ctx.fillText(this.certInfo.cert_no, 480, 880);

        // 身份证号
        this.ctx.font = "40px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'center'
        this.ctx.fillText(this.userInfo.cid, 480, 1000);

        // 右边名字
        this.ctx.font = "40px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'center'
        this.ctx.fillText(this.userInfo.name, 1020, 210);

        let certTimeArr = this.certInfo.day_time.split('-')
        // 右边年月
        this.ctx.font = "40px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'center'
        this.ctx.fillText(certTimeArr[0], 1255, 210);

        this.ctx.font = "40px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'center'
        this.ctx.fillText(certTimeArr[1], 1412, 210);

        this.ctx.font = "40px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'center'
        this.ctx.fillText(certTimeArr[0], 1043, 1045);

        this.ctx.font = "40px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'center'
        this.ctx.fillText(certTimeArr[1], 1198, 1045);

        this.ctx.font = "40px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'center'
        this.ctx.fillText(certTimeArr[2], 1315, 1045);
        this.certUrl = this.canvas.toDataURL("image/png");
        }
      }
    },
    jumpDetail(item) {
      this.$router.push(`./detail?id=${item.id}`);
    },
    
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.lesson {
  width: 100%;
  min-height: calc(100vh - 230px);
  text-align: center;
  overflow: hidden;
  canvas{
    opacity: 0;
    position: absolute;
    left: -9999px;
  }
  img{
    width: auto;
    max-width: 100%;
  }
}
.noHeight{
  height: auto;
  min-height: 0;
  img{
    width: auto;
    max-width: 100%;
    cursor: pointer;
  }
}
</style>

<style lang="scss">
</style>
